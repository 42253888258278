import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { LocalStorageKey } from '../../../../shared/constants/local-storage-key.const';
import { TokenParams } from '@okta/okta-auth-js';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map, tap } from 'rxjs/operators';
import * as loginPageTranslations from '../../../../../assets/i18n/lang/en/loginPage.json';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Location } from '@angular/common';
import { AdiHomeLoggingService } from '../../../../shared/services/adi-home-logging/adi-home-logging.service';
import { LogLevel } from '../../../../shared/constants/log-type.consts';
import { environment } from '../../../../../environments/environment';
import { LOGIN_PAGE_TRANSLATION } from '../../interfaces/loginPage.interface';
import { GuestLoginData } from '../../../../shared/interfaces/guestLoginData.interface';
import { LogObject } from '../../../../shared/interfaces/logging.interface';

@Component({
  selector: 'adi-adi-marketing-login-page',
  templateUrl: './adi-marketing-login-page.component.html',
  styleUrl: './adi-marketing-login-page.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AdiMarketingLoginPageComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  private oktaAuth = inject(OKTA_AUTH);
  private oktaAuthStateService = inject(OktaAuthStateService);
  private selectedLanguage!: string | null;
  loginPageTranslations: LOGIN_PAGE_TRANSLATION = loginPageTranslations;
  isAuthenticated$: Observable<boolean | undefined> = of(false);
  loginButtonClicked = false;
  registerButtonClicked = false;
  loginPageData: GuestLoginData | undefined;
  sanitizedUrl: SafeResourceUrl | undefined;
  isLoading: boolean = false;
  showPopup: string | null = null;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public location: Location,
    private loggingService: AdiHomeLoggingService
  ) { }

  ngOnInit(): void {
    this.checkLanguage();
    this.showPopup = localStorage.getItem('adiErrorPopup');
    this.isAuthenticated$ = this.oktaAuthStateService.authState$.pipe(
      map((res) => res.isAuthenticated),
      tap((res) => {
        if (!res && !this.location.path().includes('/login')) {
          this.oktaAuth.tokenManager.clear();
          this.clearingLocalStorage();
        }
      })
    );
    localStorage.removeItem('adiErrorPopup');
  }

  closePopup() {
    this.showPopup = null;
    localStorage.removeItem('adiErrorPopup');
  }

  checkLanguage() {
    if (!localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE)) {
      localStorage.setItem(LocalStorageKey.SELECTED_LANGUAGE, 'en_US');
    }
  }

  navigateUser() {
    const language =
      localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE) ?? 'en_US';
    this.oktaAuth.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        this.router.navigate([`${language}/dashboard`]);
      }
    });
  }

  clearingLocalStorage() {
    const items = { ...localStorage };
    for (const keys in items) {
      if (
        !keys.endsWith('_persisted') &&
        keys !== LocalStorageKey.SELECTED_LANGUAGE
      ) {
        localStorage.removeItem(keys);
      }
    }
  }

  loginData(event: GuestLoginData[]) {
    let locale: string | null;

    this.route.paramMap
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        locale = params.get('locale');
      });

    event.forEach((element: GuestLoginData) => {
      if (element.code === locale) {
        this.loginPageData = element;
      }
    });
    if (this.loginPageData?.videoLink) {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://alcon.widen.net/content/xat1zx6dyf/mp4/Adi-problem-statement-video.mp4?quality=hd&u=uxrrnh'
      );
    }
    const otherAppsData = this.loginPageData?.appsData.filter(
      (app) => app.appCode !== 'OS' && app.appCode !== 'IM'
    );
    const appCodesToFilter = ['OS', 'IM'];

    const storeData = this.loginPageData?.appsData.filter(
      (app) => appCodesToFilter.includes(app.appCode)
    );
    this.loginPageData!.appsData = otherAppsData!;
    this.loginPageData!.storeData = storeData!;

    this.cd.detectChanges();
  }

  async navigateToLogin() {
    this.loginButtonClicked = true;

    this.selectedLanguage = localStorage.getItem(
      LocalStorageKey.SELECTED_LANGUAGE
    );

    const tokenOptions: TokenParams = {
      extraParams: {
        show_reg: 'false',
        locale: this.selectedLanguage ?? 'en_US',

      },
    };

    this.oktaAuth
      .signInWithRedirect(tokenOptions)
      .then(() => {
        this.loginButtonClicked = false;
      })
      .catch((err) => {
        this.loginButtonClicked = false;
        const logObj: LogObject = {
          eventType: 'Okta Sign in Redirection',
          eventDetail: `Okta Sign in Redirection Error: ${err}`,
          logLevel: LogLevel.ERROR,
        };
        this.loggingService.sendFrontendLogs(logObj);
      });
  }

  navigateToRegister() {
    this.registerButtonClicked = true;

    this.selectedLanguage = localStorage.getItem(
      LocalStorageKey.SELECTED_LANGUAGE
    );

    const tokenOptions: TokenParams = {
      responseType: ['token'],
      scopes: ['openid', 'profile', 'email'],
      extraParams: {
        reg_page: 'true',
        show_reg: 'true',
        locale: this.selectedLanguage ?? 'en_US',
      },
    };

    this.oktaAuth.token
      .getWithRedirect(tokenOptions)
      .then(() => {
        this.registerButtonClicked = false;
      })
      .catch((err) => {
        this.registerButtonClicked = false;
        const logObj: LogObject = {
          eventType: 'Okta Self Registration Redirection',
          eventDetail: `Okta Self Registration Error: ${err}`,
          logLevel: LogLevel.ERROR,
        };
        this.loggingService.sendFrontendLogs(logObj);
      });
  }

  navigateToStore() {
    this.isLoading = true;
    const logObj: LogObject = {
      eventType: 'Navigation',
      eventDetail: `Navigating user to ${environment.STORE_URL}`,
      logLevel: LogLevel.INFO,
    };
    this.loggingService
      .sendFrontendLogs(logObj)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          window.open(environment.STORE_URL, '_blank');
        },
        error: (err) => {
          this.isLoading = false;
          window.open(environment.STORE_URL, '_blank');
        },
      });
  }
}
