@if (!(isAuthenticated$ | async)) {
<header class="header">
  <picture>
    <source media="(max-width:768px)" srcset="
          https://alcon.widen.net/content/bebthlyfud/webp/Logo_Hero_HP_MB.webp?position=c&quality=80&u=uxrrnh&use=stait
        " />
    <img alt="Logo_Adi_Home"
      src="https://alcon.widen.net/content/oey2rtrbki/webp/Logo_Hero_HP_except-MB.webp?position=c&quality=80&u=uxrrnh&use=07dv0" />
  </picture>
</header>
<div class="loginPageWrapper">
  <div class="banner-container">
    <div class="banner-content">
      <picture>
        <source media="(max-width:768px)" srcset="
              https://alcon.widen.net/content/bebthlyfud/webp/Logo_Hero_HP_MB.webp?position=c&quality=80&u=uxrrnh&use=stait
            " />
        <img alt="Logo_Adi_Home"
          src="https://alcon.widen.net/content/oey2rtrbki/webp/Logo_Hero_HP_except-MB.webp?position=c&quality=80&u=uxrrnh&use=07dv0" />
      </picture>
      <div>
        <h1>{{ loginPageTranslations.login.title }}</h1>
      </div>
      <div>
        <h2 class="overlay-text-subtitle">
          {{ loginPageTranslations.login.subtitle }}
        </h2>
      </div>
    </div>
    <div class="button-container">
      <button type="button" class="register-button hide-button" (click)="navigateToRegister()">
        {{ loginPageTranslations.login.getStarted }}
      </button>
      <button type="button" class="login-button" (click)="navigateToLogin()">
        {{ loginPageTranslations.login.login }}
      </button>
    </div>
  </div>

  <div class="container1">
    <div class="container1__content">
      <div class="container1__title">
        {{ loginPageTranslations.login.operationalChallenges }}
      </div>
      <div class="container1__subtitle">
        {{ loginPageTranslations.login.operationalDescription }}
      </div>
      <div class="btn-container">
        <button type="button" class="request-btn register-button" (click)="navigateToRegister()">
          {{ loginPageTranslations.login.RequestAccess }}
        </button>
        <button type="button" class="register-button button" (click)="navigateToLogin()">
          {{ loginPageTranslations.login.login }}
        </button>
      </div>
    </div>
    <div class="imageSection">
      @if (sanitizedUrl) {
      <video controls
        poster="https://alcon.widen.net/content/xat1zx6dyf/jpeg/Adi-problem-statement-video.jpg?u=uxrrnh&use=pdwec%22>">
        <source [src]="sanitizedUrl" type="video/mp4" />
        <track kind="captions" label="No subtitles available" />
      </video>
      }
    </div>
  </div>

  @if (loginPageData?.appsData) {
  <div class="business-section">
    <div class="text">
    {{ loginPageTranslations.login.operationalSolutions }}
      <p>{{ loginPageTranslations.login.operationalSolutionsText }}</p>
    </div>
    <ng-container *ngTemplateOutlet="
            cardSection;
            context: { card: loginPageData?.appsData, store: false }
          "></ng-container>
  </div>
  }

  @if (loginPageData?.storeData) {
  <div class="business-section store-container">
    <div class="text">
      {{ loginPageTranslations.login.storeCustomer }}
      <p>{{ loginPageTranslations.login.storeCustomerDescription }}</p>
    </div>
    <ng-container *ngTemplateOutlet="
            cardSection;
            context: { card: loginPageData?.storeData, store: true }
          "></ng-container>
  </div>
  }

  <ng-template #cardSection let-cardsData="card" let-app="store">
    <div class="card-section">
      @for (card of cardsData; track card) {
      @if (card.visible) {
      <div class="card">
        <div class="card-image">
          <picture>
            <source media="(max-width:767px)" [srcset]="card.appImageMobile" />
            <source media="(max-width:1023px)" [srcset]="card.appImageTablet" />
            <source media="(max-width:1279px)" [srcset]="card.appImageSmallDesktop" />
            <img [src]="card.appImageDesktop" [alt]="card.appName" />
          </picture>
        </div>
        <div class="card-body">
          <h3>{{ card.appName }}</h3>
          <p class="card-text" [ngClass]="{ 'os': card.appCode === 'OS' }">{{ card.appDescription }}</p>


          @if (app === true && card.appCode =="OS") {
          <div class="card-link" (click)="navigateToStore()">
            <img src="/assets/images/right-arrow.svg" alt="arrow sign" />
            <span class="text-border">{{
              loginPageTranslations.login.goToStore
              }}</span>
          </div>
          }
          @if (app === true && card.appCode =="IM") {
          <div class="card-link" (click)="navigateToStore()">
            <img src="/assets/images/right-arrow.svg" alt="arrow sign" />
            <span class="text-border">{{
              loginPageTranslations.login.goToInventory
              }}</span>
          </div>
          }
        </div>
      </div>
      }
      }
    </div>
  </ng-template>
  <adi-footer (data)="loginData($event)"></adi-footer>
</div>
@if (loginButtonClicked || registerButtonClicked || isLoading) {
<adi-loader> </adi-loader>
}

@if (showPopup) {
<adi-popup [title]="loginPageTranslations.popup.title" [body]="loginPageTranslations.popup.body"
  [buttonTxt]="loginPageTranslations.popup.buttonTxt" (closePopup)="closePopup()" [showCloseIcon]="true"></adi-popup>
}
}